<template>
  <div class="home-cont">
    <div class="h-header">
      <div @click="goPath('/user')">
        <img v-if="$init.pass.headimg" :src="$init.pass.headimg" class="logo" alt="">
        <img v-else src="@/assets/image/logo.png" class="logo" alt="">
      </div>
      <div class="logo-name" @click="goPath('/user')">
        <div class="label">{{$init.pass.nickname}}</div>
        <div class="en">{{phoneStr}}</div>
      </div>
      <div class="h-more" @click="goPath('/user')">
        <right theme="two-tone" :fill="['#eee' ,'#2F88FF']"/>
      </div>
    </div>
    
    <div class="card-out">
      <div class="card-cont">
        <div class="card-item" @click="goPath('/service/?type=add')">
          <div class="card-icon">
            <img src="@/assets/image/home/1.png" alt="">
          </div>
          <div class="card-text">我的保障</div>
          <div class="card-more">
            <right theme="two-tone" :fill="['#a7a7a7' ,'#2F88FF']"/>
          </div>
        </div>
        <div class="card-item" @click="goPath('/service/?type=ask')">
          <div class="card-icon">
            <img src="@/assets/image/home/2.png" alt="">
          </div>
          <div class="card-text">我的服务</div>
          <div class="card-more">
            <right theme="two-tone" :fill="['#a7a7a7' ,'#2F88FF']"/>
          </div>
        </div>
        <div class="card-item" @click="noPath">
          <div class="card-icon">
            <img src="@/assets/image/home/3.png" alt="">
          </div>
          <div class="card-text">我的资产</div>
          <div class="card-more">
            <right theme="two-tone" :fill="['#a7a7a7' ,'#2F88FF']"/>
          </div>
        </div>
        <div class="card-item" @click="noPath">
          <div class="card-icon">
            <img src="@/assets/image/home/4.png" alt="">
          </div>
          <div class="card-text">红包卡券</div>
          <div class="card-more">
            <right theme="two-tone" :fill="['#a7a7a7' ,'#2F88FF']"/>
          </div>
        </div>
      </div>

      <!-- <div class="d">
        <img src="@/assets/image/home/9.png" alt=""  @click="goPath('/query/siteList')">
      </div> -->

      <div class="b card-cont">
        <img class="l" src="@/assets/image/home/10.png" alt="">
        <div class="r">
          <div class="title">全品牌官方售后热线及服务站查询</div>
          <div class="btns">
            <div class="btn" @click="goPath('/query/siteList')">官方维修站</div>
            <div class="btn" @click="goPath('/query/400')">售后热线</div>
          </div>
        </div>
      </div>

      <div class="card-cont">
        <div class="card-item" @click="goPath('/contact')">
          <div class="card-icon">
            <img src="@/assets/image/home/8.png" alt="">
          </div>
          <div class="card-text">联系我们</div>
          <div class="card-more">
            <right theme="two-tone" :fill="['#a7a7a7' ,'#2F88FF']"/>
          </div>
        </div>
        <div class="card-item" @click="goPath('/join/home')">
          <div class="card-icon">
            <img src="@/assets/image/home/7.png" alt="">
          </div>
          <div class="card-text">入驻申请</div>
          <div class="card-more">
            <right theme="two-tone" :fill="['#a7a7a7' ,'#2F88FF']"/>
          </div>
        </div>
        <!-- <div class="card-item">
          <div class="card-icon">
            <img src="@/assets/image/home/6.png" alt="">
          </div>
          <div class="card-text">设置</div>
          <div class="card-more">
            <right theme="two-tone" :fill="['#a7a7a7' ,'#2F88FF']"/>
          </div>
        </div> -->
        <div class="card-item" @click="goPath('/about')">
          <div class="card-icon">
            <img src="@/assets/image/home/5.png" alt="">
          </div>
          <div class="card-text">关于我们</div>
          <div class="card-more">
            <right theme="two-tone" :fill="['#a7a7a7' ,'#2F88FF']"/>
          </div>
        </div>
      </div>
    </div>  

  </div>
</template>

<script>
import {Right} from '@icon-park/vue'
export default {
  components: {
    Right
  },
  name: 'Home',
  data () {
    return {

    }
  },
  mounted () {
    
  },
  methods: {
    goPath (path) {
      this.$router.push(path)
    },
    noPath () {
      this.$createDialog({
          type: 'alert',
          title: '',
          content: '功能开发中',
          icon: ''
        }).show()
    },
  },
  computed: {
    phoneStr () {
      return this.$init.pass.phone.substring(0, 3) + '****' + this.$init.pass.phone.substring(7, 11)
    }
  },  
}
</script>

<style lang="scss" scoped>
.home-cont{
  min-height: 100.1vh;
  background-image: url(../assets/image/bg1.png);
  background-size: 100% 340px;
  background-position: 0 0;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.h-header{
  display: flex;
  padding: 60px 25px 100px 25px;
  align-items: center;
  justify-content: center;
}
.logo{
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  margin-right: 28px;
}
.logo-name{
  flex: 10;
  color: #fff;
  font-size: 28px;
  .en{
    font-size: 36px;
    line-height: 56px;
  }
  .label{
    line-height: 48px;
  }
}
.btn-cont{
  padding-top: 100px;
}
.form-sub{
  width: 410px;
  height: 100px;
  background: #1F955B;
  border-radius: 50px;
  text-align: center;
  font-size: 32px;
  line-height: 100px;
  color: #fff;
  margin: 0 auto;
  border: 4px solid #fff;
}

.card-out{
  padding: 0 25px;
}
.card-cont{
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  padding: 25px 40px;
  border-radius: 8px;
  margin-bottom: 40px;
  background-color: #fff;
  .card-item{
    height: 100px;
    display: flex;
    border-top: 1px solid #f3f3f3;
    justify-content: center;
    align-items: center; 
    &:first-child{
      border: none;
    }
  }
  .card-icon{
    width: 80px;
    img{
      display: block;
      width: 42px;
    }
  }
  .card-text{
    line-height: 100px;
    font-size: 32px;
    flex: 10;
  }
  .card-more{
    font-size: 40px;
  }
}
.d{
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin-bottom: 40px;
  img{
    display: block;
    width: 100%;
  }
}

.b{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0;
  .l{
    display: block;
    height: 119px;
    margin-left: 20px;
    margin-right: 15px;
  }
  .r{
    flex: 10;
    .title{
      font-size: 30px;
      color: #0b80f1;
      line-height: 50px;
    }
  }
  .btns{
    display: flex;
    align-items: center;
    justify-content: center;
    .btn{
      display: block;
      padding: 0 30px;
      line-height: 60px;
      background-color: #0b80f1;
      color: #fff;
      border-radius: 30px;
      font-size: 24px;
      margin: 10px 20px;
    }
  }
}

.h-more{
  font-size: 48px;
}
</style>